import React, { useState } from "react";
import { Link } from "react-router-dom";
import { fetchLocations } from "./api";

import "./LandingPage.scss";

const FALLBACK_LOCATION_NAME = "Online";
import { EXISTING_LOCATIONS } from "./lib";

const LocationLookup = () => {
  const [zipCode, setZipCode] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [result, setResult] = useState({ name: "", code: "" });

  const onZipCodeChange = e => {
    const nextZip = e.target.value;
    setZipCode(nextZip);

    if (nextZip.length === 5) {
      fetchLocation(nextZip);
    }
  };

  const fetchLocation = async zip => {
    setIsSubmitting(true);
    const response = await fetchLocations(zip);
    setResult(response);
    setIsSubmitting(false);
  };

  const matchingLocation =
    !!result.name && EXISTING_LOCATIONS.find(l => l.code === result.code);

  return (
    <div className="LocationLookup">
      <form
        className="LocationLookup__form"
        onSubmit={e => {
          e.preventDefault();
          fetchLocation(zipCode);
        }}
      >
        <div
          style={{
            display: `flex`,
            flexDirection: `column`,
          }}
        >
          <label htmlFor="zip_code" style={{ textAlign: `left` }}>
            Zip Code
          </label>
          <input id="zip_code" type="text" onChange={onZipCodeChange} />
        </div>
        <button
          style={{ maxHeight: `36px` }}
          type="submit"
          className={`button${isSubmitting ? " button--isSubmitting" : ""}`}
          disabled={!zipCode || isSubmitting}
        >
          Find Location
        </button>
      </form>
      {!!result.name && (
        <div style={{ margin: `2rem auto` }}>
          {result.name === FALLBACK_LOCATION_NAME ? (
            <>
              <p>No matching locations for zip code: {zipCode}.</p>
              <Link to="/schedule/online" className="link-button">
                View Online Schedule
              </Link>
            </>
          ) : (
            <Link
              to={`/schedule/${matchingLocation.slug}`}
              className="link-button"
            >
              View {result.name} Schedule
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

const LandingPage = () => (
  <div className="LandingPage">
    <section className="LandingPage__setting">
      <h1>Online Schedule</h1>
      <p>View our upcoming schedule for proctored online practice tests</p>
      <Link to="/schedule/online" className="link-button">
        Get Started
      </Link>
    </section>
    <section
      className="LandingPage__setting"
      style={{
        backgroundColor: `var(--dark)`,
        color: `#fff`,
      }}
    >
      <h1>In Person Schedule</h1>
      <p>Search our upcoming practice test schedule by zip code</p>
      <LocationLookup />
    </section>
  </div>
);

export default LandingPage;
