import Honeybadger from "honeybadger-js";

let honeybadger = {};

if (process.env.NODE_ENV === "production") {
  honeybadger = Honeybadger.configure({
    apiKey: process.env.HONEYBADGER_JS_API_KEY,
    environment: process.env.RAILS_ENV || process.env.NODE_ENV,
    silent: false,
    ignoreErrors: false,
    revision: process.env.HEROKU_SLUG_COMMIT,
  });
} else {
  honeybadger = Honeybadger.configure({
    disabled: true,
  });
}

export default honeybadger;
