import React from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import LandingPage from "./LandingPage";
import LocationPage from "./LocationPage";

const Pages = () => (
  <Switch>
    <Route path="/schedule" component={LandingPage} exact />
    <Route path="/schedule/:slug" component={LocationPage} exact />
  </Switch>
);

const App = () => {
  return (
    <Router>
      <Pages />
    </Router>
  );
};

export default App;
