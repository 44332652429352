import React from "react";
import { render } from "react-dom";
import App from "../apps/testSchedule";

document.addEventListener("DOMContentLoaded", () => {
  const el = document.getElementById("app");
  if (!el) return;

  render(<App />, el);
});
