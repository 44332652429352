import fetch from "cross-fetch"; // ponyfill for old browsers
import honeybadger from "./honeybadger";

// originally copied from react-on-rails
const Authenticity = {
  token() {
    const token = document.querySelector('meta[name="csrf-token"]');
    if (token && token instanceof window.HTMLMetaElement) {
      return token.content;
    }
    return null;
  },
};

// from here, below pulled from dashboard on 3/13/2020
// with only slight tweaks to what was exported

// token helpers

const getTokenHeaders = () => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  "X-CSRF-Token": Authenticity.token(),
});

const getJSONHeaders = {
  credentials: "same-origin",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

// parse helpers

const buildErrorsString = (statusText, { errors, error }) => {
  let errorsString = "";
  for (let key in errors) {
    if (Array.isArray(errors[key])) {
      errorsString += `${capitalize(key)}: ${errors[key].join(", ")}, `;
    } else {
      errorsString += `${capitalize(key)}: ${errors[key]}, `;
    }
  }
  if (errorsString.length) {
    errorsString = errorsString
      .substr(0, errorsString.length - 2)
      .replace(/_/g, " ");
  } else {
    errorsString = error || statusText;
  }
  return errorsString;
};

const buildResError = (response, data, message) => {
  let error = new Error(message);
  error.status = response.status;
  error.statusText = response.statusText;
  error.response = response;
  error.body = data;
  error.errors = data.errors;

  return error;
};

export const logErrorToHoneybadger = (error, info) =>
  honeybadger.notify(error, info);

const checkStatus = response => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const contentType = response.headers.get("content-type");
  if (contentType && contentType.includes("application/json")) {
    return response.json().then(data => {
      const message = buildErrorsString(response.statusText, data);
      const error = buildResError(response, data, message);

      throw error;
    });
  }

  throw new Error(response.statusText);
};

const parseJSON = payload => {
  if (typeof payload === "string") {
    return JSON.parse(payload);
  }
  return payload.json();
};

// actual calls

export const GET = (url, fetchOpts = {}) =>
  fetch(url, { ...getJSONHeaders, ...fetchOpts })
    .then(checkStatus)
    .then(parseJSON);

const _post = (url, payload) =>
  fetch(url, {
    method: "POST",
    credentials: "same-origin",
    headers: getTokenHeaders(),
    body: JSON.stringify(payload),
  });

export const POST = (url, payload, options = {}) => {
  const default_options = {
    checkStatus: true,
    parseJSON: true,
  };
  options = { ...default_options, ...options };
  if (options.checkStatus && options.parseJSON) {
    return _post(url, payload).then(checkStatus).then(parseJSON);
  } else if (options.checkStatus) {
    return _post(url, payload).then(checkStatus);
  } else if (options.parseJSON) {
    return _post(url, payload).then(parseJSON);
  } else {
    return _post(url, payload);
  }
};

export const POST_FORMDATA = (url, formData) => {
  // The lack of Content-Type is intentional: multipart/form-data is assumed.
  return fetch(url, {
    method: "POST",
    credentials: "same-origin",
    headers: {
      Accept: "application/json",
      "X-CSRF-Token": Authenticity.token(),
    },
    body: formData,
  }).then(checkStatus);
};

export const PATCH = (url, payload) =>
  fetch(url, {
    method: "PATCH",
    credentials: "same-origin",
    headers: getTokenHeaders(),
    body: JSON.stringify(payload),
  })
    .then(checkStatus)
    .then(parseJSON);

export const PUT = (url, payload) =>
  fetch(url, {
    method: "PUT",
    credentials: "same-origin",
    headers: getTokenHeaders(),
    body: JSON.stringify(payload),
  })
    .then(checkStatus)
    .then(parseJSON);

export const DELETE = (url, options = {}, payload) => {
  if (options.parseJSON) {
    return fetch(url, {
      method: "DELETE",
      credentials: "same-origin",
      headers: getTokenHeaders(),
      body: JSON.stringify(payload),
    })
      .then(checkStatus)
      .then(parseJSON);
  } else {
    return fetch(url, {
      method: "DELETE",
      credentials: "same-origin",
      headers: getTokenHeaders(),
      body: JSON.stringify(payload),
    }).then(checkStatus);
  }
};
