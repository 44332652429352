import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchLocationTests } from "./api";
import AirBalloonFloating from "./AirBalloonFloating";
import { EXISTING_LOCATIONS } from "./lib";

import "./LocationPage.scss";

const NoContent = _ => (
  <div style={{ textAlign: "center", maxWidth: 660, margin: "auto" }}>
    <AirBalloonFloating />
    <h3>Hmmm...</h3>
    <p>{`We can't seem to find an upcoming test for this location.`}</p>
    <p> Please reach out to our team for more details!</p>
  </div>
);

const LocationPage = ({
  match: {
    params: { slug },
  },
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [testTypes, setTestTypes] = useState([]);
  const [practiceTests, setPracticeTests] = useState([]);
  const [activeTestType, setActiveTestType] = useState(null);
  const [activeTests, setActiveTests] = useState([]);

  const location = EXISTING_LOCATIONS.find(l => l.slug === slug);

  useEffect(() => {
    document.title = `${
      !!location ? `${location.name} ` : ""
    }Test Schedule - Private Prep`;
  }, [location]);

  useEffect(() => {
    if (!!location && !location.covidClosed) {
      setIsLoading(true);
      fetchLocationTests(location.codes || location.code)
        .then(({ test_types, practice_tests }) => {
          setTestTypes(test_types);
          setPracticeTests(
            practice_tests
              .sort((a, b) => {
                a = new Date(a.startsAt);
                b = new Date(b.startsAt);
                return a > b ? -1 : a < b ? 1 : 0;
              })
              .map(test => ({
                ...test,
                accommodations: test.accommodations.map(accomm => ({
                  ...accomm,
                  name: accomm.name.replace("SAT Subject Test - ", ""),
                })),
              }))
          );
        })
        .finally(() => setIsLoading(false));
    }
  }, [location]);

  useEffect(() => {
    const filteredTests = !activeTestType
      ? practiceTests
      : practiceTests.filter(test => test.test_type === activeTestType);

    setActiveTests(filteredTests);
  }, [activeTestType, practiceTests]);

  if (!location) {
    return <div>{`We can't find a matching location for ${code}`}</div>;
  }

  return (
    <div className="Location">
      <header className="Location__header">
        <h1 className="title">{location.name} Test Schedule</h1>
        {location.covidClosed ? (
          <>
            <p>
              Due to COVID-19 disruptions, our {location.name} location is
              conducting all practice tests online.
            </p>
            <Link to="/schedule/online" className="link-button">
              View Online Schedule
            </Link>
          </>
        ) : (
          <p>
            Below you will find a selection of our upcoming, proctored tests.
          </p>
        )}
        <p>
          To register, login to the exam through the{" "}
          <a href="https://dashboard.privateprep.com/login">
            Private Prep Dashboard
          </a>
          .
        </p>
        <p>
          If you have questions or do not have an account, please{" "}
          <a href="https://privateprep.com/contact?utm_source=online-tests-schedule">
            reach out to us{" "}
          </a>
          {`and we'll be in touch!`}
        </p>
      </header>
      {!location.covidClosed && <hr />}
      {!location.covidClosed && isLoading && <p>Loading...</p>}
      {!location.covidClosed && !isLoading && !!testTypes.length && (
        <React.Fragment>
          <div className="filters">
            <h2>Test Types</h2>
            <div
              className="radio-group"
              style={
                testTypes.length < 5
                  ? { flexDirection: "row", maxWidth: 420 }
                  : {}
              }
            >
              <div className="radio">
                <label className="radio-label" htmlFor={"all-tests"}>
                  <input
                    id={"all-tests"}
                    type="radio"
                    value={""}
                    checked={!activeTestType}
                    onChange={_ => setActiveTestType()}
                  />
                  <span className="option-label">All</span>
                </label>
              </div>
              {testTypes.map(testType => (
                <div className="radio" key={testType.test_type_id}>
                  <label className="radio-label" htmlFor={testType.test_type}>
                    <input
                      type="radio"
                      id={testType.test_type}
                      value={testType.test_type}
                      checked={activeTestType === testType.test_type}
                      onChange={_ => setActiveTestType(testType.test_type)}
                    />
                    <span className="option-label">{testType.test_type}</span>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </React.Fragment>
      )}
      {!isLoading && !!activeTests.length && (
        <div className="test-container">
          <header className="test-container__header">
            <h2>Available Tests</h2>
            <p className="label">{activeTests.length}</p>
          </header>
          <table className="table">
            <thead>
              <tr>
                <th className="testType">Test Type</th>
                <th className="testCenter">Test Center</th>
                <th className="startsAt">Start Time</th>
                <th className="accommodations">Accommodations</th>
              </tr>
            </thead>
            <tbody>
              {activeTests.map(test => {
                const localDate = new Date(test.starts_at);
                return (
                  <tr key={`${test.id}-${test.test_type}`}>
                    <td className="testType">{test.test_type}</td>
                    <td className="testCenter">{test.test_center}</td>
                    <td className="startsAt">
                      {localDate.toLocaleString("default", {
                        timeZoneName: "short",
                      })}
                    </td>
                    <td className="accommodations">
                      <ul>
                        {test.accommodations.map(accom => (
                          <li key={accom.id}>{accom.name}</li>
                        ))}
                      </ul>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {!isLoading && !testTypes.length && <NoContent />}
    </div>
  );
};

export default LocationPage;
