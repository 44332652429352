export const ONLINE_LOCATION = {
  id: 7,
  name: "Online",
  slug: "online",
  code: "ol",
  covidClosed: false,
};

// locations as of 10/16/2020
export const EXISTING_LOCATIONS = [
  ONLINE_LOCATION,
  {
    id: 1,
    name: "NYC",
    slug: "new-york-city",
    code: "nyc",
    covidClosed: false,
  },
  {
    id: 2,
    name: "Westchester",
    slug: "westchester",
    code: "ct,wc", // as parentMarket
    covidClosed: false,
  },
  {
    id: 3,
    name: "Long Island",
    slug: "long-island",
    code: "li",
    covidClosed: false,
  },
  { id: 4, name: "DC Metro", slug: "dc-metro", code: "dc", covidClosed: false },
  {
    id: 5,
    name: "Northern New Jersey",
    slug: "northern-new-jersey",
    code: "nj",
    covidClosed: false,
  },
  {
    id: 6,
    name: "Los Angeles",
    slug: "los-angeles",
    code: "la",
    covidClosed: true,
  },

  {
    id: 8,
    name: "Connecticut",
    slug: "connecticut",
    code: "ct",
    codes: "ct,wc", // as parentMarket
    covidClosed: false,
  },
  {
    id: 9,
    name: "South Florida",
    slug: "south-florida",
    code: "fl",
    covidClosed: true,
  },
  {
    id: 10,
    name: "Central New Jersey",
    slug: "central-new-jersey",
    code: "cnj",
    covidClosed: false,
  },
  {
    id: 12,
    name: "International",
    slug: "international",
    code: "int",
    covidClosed: true,
  },
];
